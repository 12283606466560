<template>
	<div class="commonHeight">
		<div class="flex header">
			<h2>区域数据分析</h2>
		</div>
		<el-form ref="form" class="searchCriteria" label-suffix=":" :model="searchForm" label-width="100px">
			<div class="flex mb-1">
				<el-form-item label="停车场">
					<el-select v-model="searchForm.parkId" placeholder="请选择停车场">
						<el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList"
											 :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="统计时间">
					<el-date-picker
							v-model="searchForm.beginDate"
							type="date"
							placeholder="开始时间">
					</el-date-picker>
					--
					<el-date-picker
							v-model="searchForm.endDate"
							type="date"
							placeholder="结束时间">
					</el-date-picker>
				</el-form-item>
			</div>
			<div class="flex mb-1">
				<el-form-item label="统计时间段">
					<el-time-picker
							is-range
							v-model="searchForm.timePick"
							range-separator="-"
							start-placeholder="开始时间"
							end-placeholder="结束时间"
							placeholder="选择时间段">
					</el-time-picker>
				</el-form-item>
				<div class="averageWid btnGroup">
					<el-button @click="searchData">查询</el-button>
					<el-button @click="reloadSearch">重置</el-button>
				</div>
			</div>

		</el-form>
		<!--		单天-->
		<areaDay v-if="showOneDay" :inOut="inOut" :entryCarOneDay="entryCarOneDay" :sentryCarOneDay="sentryCarOneDay"
						 :count="count" :parkRateOneDay="parkRateOneDay" :showParkingRate="showParkingRate"></areaDay>
		<!--		时间段-->
		<areaPeriod v-else :parkRate="parkRate" :lineInPark="lineInPark" :lineOutPark="lineOutPark" :earnings="earnings"
								:entryCarTimes="entryCarTimes" :outCarTimes="outCarTimes" :countByTimes="countByTimes"
								:showParkingRate="showParkingRate"></areaPeriod>
	</div>

</template>

<script>
import {
	getCarParkInfoList,
	getCarPassingByOneDay,
	getCarStatisticsByTime,
	getSentryCarStatistics,
	getNumericalStatistics,
	getParkingRateByCondition,
	getCarEntryStatistics,
	getCarOutStatisticsByCondition,
	getAmountStatisticsByCondition,
	getLessThanHalfHourByTime
} from "@/api/api";
import moment from "moment";

moment.locale("zh-cn");

export default {
	name: "areaAmount",
	data() {
		return {
			showOneDay: true,
			searchForm: {
				parkId: 0,
				beginDate: "",
				endDate: "",
				timePick: ""
			},
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			parkingList: [],
			entryCarOneDay: {
				rows: [],
				chartData: []
			},
			sentryCarOneDay: {
				rows: [],
				chartData: []
			},
			inOut: {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE", "#5AB1EF"],
				listscore: [
					{ "name": "进场频次", "yValue": [] },
					{ "name": "出场频次", "yValue": [] }]
			},
			count: {
				inPark: 0,
				outPark: 0,
				incomeAmount: 0,
				lossAmount: 0,
				lossCoupon: 0,
				parkingRate: 0
			},
			parkRate: {
				Yunit: "%",
				xValue: [],
				colors: ["#FA6E86"],
				listscore: [
					{ "name": "停车率", "yValue": [] }
				]
			},
			parkRateOneDay: {
				Yunit: "%",
				xValue: [],
				colors: ["#FA6E86"],
				listscore: [
					{ "name": "停车率", "yValue": [] }
				]
			},
			lineInPark: {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE", "#5AB1EF", "#FFB980", "#0067A6","#DC5BF0"],
				listscore: []
			},
			lineOutPark: {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE", "#5AB1EF", "#FFB980", "#0067A6","#DC5BF0"],
				listscore: []
			},
			earnings: {
				Yunit: "元",
				xValue: [],
				colors: ["#19D4AE", "#FA6E86", "#FFB980"],
				listscore: [
					{ "name": "收入金额", "yValue": [] },
					{ "name": "总亏损金额", "yValue": [] },
					{ "name": "优惠券亏损金额", "yValue": [] }]
			},
			entryCarTimes: {
				rows: [],
				chartData: []
			},
			outCarTimes: {
				rows: [],
				chartData: []
			},
			countByTimes: {
				inPark: 0,
				outPark: 0,
				incomeAmount: 0,
				lossAmount: 0,
				parkingRate: 0,
				lossCoupon: 0
			},
			dateList: [],
			inTime: moment().subtract(10, "days").format("YYYY-MM-DD"),
			showParkingRate: true
		};
	},
	mounted() {
		this.getParkingList();
	},
	methods: {
		initData() {
			this.count = {
				inPark: 0,
				outPark: 0,
				incomeAmount: 0,
				lossAmount: 0,
				lossCoupon: 0,
				parkingRate: 0
			};
			this.inOut = {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE", "#5AB1EF"],
				listscore: [
					{ "name": "进场频次", "yValue": [] },
					{ "name": "出场频次", "yValue": [] }]
			};
			this.entryCarOneDay = {
				rows: [],
				chartData: []
			};
			this.sentryCarOneDay = {
				rows: [],
				chartData: []
			};
			this.parkRateOneDay = {
				Yunit: "%",
				xValue: [],
				colors: ["#FA6E86"],
				listscore: [
					{ "name": "停车率", "yValue": [] }
				]
			};
			this.getCarPassingInAndOut();
			this.getCarPassingInByMemberType();
			this.getCarPassingOutByMemberType();
			this.getNumericalFromServe();
			this.getparkingRateByOneDay();
		},
		getParkingList() {
			let params = {
				page: this.page.current,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.parkingList = res.data.rows;
				this.searchForm.parkId = res.data.rows[0].id;
				this.initData();
			});
		},

		//车辆进出场情况
		getCarPassingInAndOut() {
			let params = {
				parkId: this.searchForm.parkId,
				inTime: this.inTime
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			getCarPassingByOneDay(JSON.stringify(params)).then(res => {
				let arr = [], arr2 = [], arr3 = [];
				if (res.data.data.length > 0) {
					res.data.data.forEach(i => {
						arr.push(i.gate);
						arr2.push(i.entryCount);
						arr3.push(i.outCount);
						if (i.gate == "总数量") {
							this.count.inPark = i.entryCount;
							this.count.outPark = i.outCount;
						}
					});
				}
				this.inOut.xValue = arr;
				this.inOut.listscore[0].yValue = arr2;
				this.inOut.listscore[1].yValue = arr3;
			});
		},

		//会员类型--进场
		getCarPassingInByMemberType() {
			let params = {
				parkId: this.searchForm.parkId,
				inTime: this.inTime
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			getCarStatisticsByTime(JSON.stringify(params)).then(res => {
				let arr = [], arr2 = [];
				if (res.data.data.length > 0) {
					res.data.data.forEach(i => {
						arr.push(i.memberType);
						arr2.push(i.memberTypeNum);
					});
				}
				this.entryCarOneDay.rows = arr;
				this.entryCarOneDay.chartData = arr2;
			});
		},

		//会员类型--出场
		getCarPassingOutByMemberType() {
			let params = {
				parkId: this.searchForm.parkId,
				outTime: this.inTime
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			getSentryCarStatistics(JSON.stringify(params)).then(res => {
				let arr = [], arr2 = [];
				if (res.data.data.list.length > 0) {
					res.data.data.list.forEach(i => {
						arr.push(i.memberType);
						arr2.push(i.memberTypeNum);
					});
				}
				this.sentryCarOneDay.rows = arr;
				this.sentryCarOneDay.chartData = arr2;
			});
		},

		//数值统计
		getNumericalFromServe() {
			let params = {
				parkId: this.searchForm.parkId,
				outTime: this.inTime
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			let that = this;
			getNumericalStatistics(JSON.stringify(params)).then(res => {
				if (res.data.data[0] != null) {
					that.count.incomeAmount = res.data.data[0].incomeTotal;
					that.count.lossAmount = res.data.data[0].totalLoss;
					that.count.lossCoupon = res.data.data[0].couponLoass;
				}
			});
		},

		//停车率--单天
		getparkingRateByOneDay() {
			let params = {
				parkId: this.searchForm.parkId,
				inTime: this.inTime,
				status: 0
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			getParkingRateByCondition(JSON.stringify(params)).then(res => {
				//this.count.parkingRate = Number(res.data.data.rate)*100
                if (res.data.data){
                    this.parkRateOneDay.xValue = res.data.data.dateList;
                    /*let arr = []
                    res.data.data.rateList.forEach(i=>{
                        arr.push(i*100)
                    })*/
                    this.parkRateOneDay.listscore[0].yValue = res.data.data.rateList;
                }

			});
		},

		//停车率--时间段
		getparkingRateByTime() {
			let params = {
				parkId: this.searchForm.parkId,
				inTime: moment(this.searchForm.beginDate).format("YYYY-MM-DD"),
				outTime: moment(this.searchForm.endDate).format("YYYY-MM-DD"),
				status: 1
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			getParkingRateByCondition(JSON.stringify(params)).then(res => {
				this.parkRate.xValue = res.data.data.dateList;
				this.parkRate.listscore[0].yValue = res.data.data.rateList;
			});
		},

		//车辆进场情况分析
		getCarPassingByTimes() {
			let params = {
				parkId: this.searchForm.parkId,
				inTime: moment(this.searchForm.beginDate).format("YYYY-MM-DD"),
				outTime: moment(this.searchForm.endDate).format("YYYY-MM-DD")
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			getCarEntryStatistics(JSON.stringify(params)).then(res => {
				this.lineInPark.xValue = res.data.data.dateList;
				this.countByTimes.inPark = res.data.data.count ? res.data.data.count : 0;
				let list = res.data.data.countList;
				if (list.length > 0) {
					list.forEach(i => {
						let object = {
							name: "",
							yValue: []
						};
						object.name = i.name;
						object.yValue = i.yvalue;
						this.lineInPark.listscore.push(object);
					});
				}
			});
		},

		//车辆出场情况分析
		getCarOutStatisByTimes() {
			let params = {
				parkId: this.searchForm.parkId,
				entryTime: moment(this.searchForm.beginDate).format("YYYY-MM-DD"),
				outTime: moment(this.searchForm.endDate).format("YYYY-MM-DD")
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			getCarOutStatisticsByCondition(JSON.stringify(params)).then(res => {
				this.lineOutPark.xValue = res.data.data.dateList;
				this.countByTimes.outPark = res.data.data.allCount;
				let list = res.data.data.parkingGateList;
				if (list.length > 0) {
					list.forEach(i => {
						let object = {
							name: "",
							yValue: []
						};
						object.name = i.name;
						object.yValue = i.yvalue;
						this.lineOutPark.listscore.push(object);
					});
				}
			});
		},

		//停车场收支金额
		getParkAmountByCondition() {
			let params = {
				parkId: this.searchForm.parkId,
				entryTime: moment(this.searchForm.beginDate).format("YYYY-MM-DD"),
				outTime: moment(this.searchForm.endDate).format("YYYY-MM-DD")
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			getAmountStatisticsByCondition(JSON.stringify(params)).then(res => {
				let num = 0, num2 = 0, num3 = 0;
				if (res.data.data.length > 0) {
					res.data.data.forEach(i => {
						this.earnings.xValue.push(i.outTimeDay);
						this.earnings.listscore[0].yValue.push(i.incomeTotal);
						this.earnings.listscore[1].yValue.push(i.totalLoss);
						this.earnings.listscore[2].yValue.push(i.couponLoass);
						num = num + i.incomeTotal;
						num2 = num2 + i.totalLoss;
						num3 = num3 + i.couponLoass;
					});
					this.countByTimes.incomeAmount = num;
					this.countByTimes.lossAmount = num2;
					this.countByTimes.lossCoupon = num3;
				}
			});
		},

		//会员类型--进场--时间段
		getCarPassingInByMemberTypeAndTime() {
			let params = {
				parkId: this.searchForm.parkId,
				entryTime: moment(this.searchForm.beginDate).format("YYYY-MM-DD"),
				outTime: moment(this.searchForm.endDate).format("YYYY-MM-DD")
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			getCarStatisticsByTime(JSON.stringify(params)).then(res => {
				let arr = [], arr2 = [];
				if (res.data.data.length > 0) {
					res.data.data.forEach(i => {
						arr.push(i.memberType);
						arr2.push(i.memberTypeNum);
					});
				}
				this.entryCarTimes.rows = arr;
				this.entryCarTimes.chartData = arr2;
			});
		},

		//会员类型--出场
		getCarPassingOutByMemberTypeAndTime() {
			let params = {
				parkId: this.searchForm.parkId,
				entryTime: moment(this.searchForm.beginDate).format("YYYY-MM-DD"),
				outTime: moment(this.searchForm.endDate).format("YYYY-MM-DD")
			};
			if (this.searchForm.timePick.length > 0) {
				params.beginHour = moment(this.searchForm.timePick[0]).format("HH:mm:ss");
				params.endHour = moment(this.searchForm.timePick[1]).format("HH:mm:ss");
			}
			getLessThanHalfHourByTime(JSON.stringify(params)).then(res => {
				let arr = [], arr2 = [];
				if (res.data.data.length > 0) {
					res.data.data.forEach(i => {
						arr.push(i.memberType);
						arr2.push(i.memberTypeNum);
					});
				}
				this.outCarTimes.rows = arr;
				this.outCarTimes.chartData = arr2;
			});
		},

		searchData() {
			if (this.searchForm.parkId == 10 || this.searchForm.parkId == 11) {
				this.showParkingRate = false;
			} else {
				this.showParkingRate = true;
			}
			let day = moment(this.searchForm.beginDate);
			let day2 = moment(this.searchForm.endDate);
			let same = day.isSame(day2, "day");
			if (this.searchForm.beginDate == "" || this.searchForm.endDate == "") {
				this.showOneDay = true;
				this.initData();
			} else if (same == true) {
				this.showOneDay = true;
				this.inTime = moment(this.searchForm.beginDate).format("YYYY-MM-DD");
				this.initData();
			} else {
				this.showOneDay = false;
				this.initCharts();
			}
		},

		reloadSearch() {
			this.searchForm = {
				parkId: "",
				beginDate: "",
				endDate: "",
				timePick: ""
			};
			this.showOneDay = true;
			this.getParkingList();
		},
		initCharts() {
			this.countByTimes = {
				inPark: 0,
				outPark: 0,
				incomeAmount: 0,
				lossAmount: 0,
				parkingRate: 0,
				lossCoupon: 0
			};
			this.parkRate = {
				Yunit: "%",
				xValue: [],
				colors: ["#FA6E86"],
				listscore: [
					{ "name": "停车率", "yValue": [] }
				]
			};
			this.lineInPark = {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE", "#5AB1EF", "#FFB980", "#0067A6","#DC5BF0"],
				listscore: []
			};
			this.lineOutPark = {
				Yunit: "",
				xValue: [],
				colors: ["#19D4AE", "#5AB1EF", "#FFB980", "#0067A6","#DC5BF0"],
				listscore: []
			};
			this.earnings = {
				Yunit: "元",
				xValue: [],
				colors: ["#19D4AE", "#FA6E86", "#FFB980"],
				listscore: [
					{ "name": "收入金额", "yValue": [] },
					{ "name": "总亏损金额", "yValue": [] },
					{ "name": "优惠券亏损金额", "yValue": [] }]
			};
			this.entryCarTimes = {
				rows: [],
				chartData: []
			};
			this.outCarTimes = {
				rows: [],
				chartData: []
			};
			this.getparkingRateByTime();
			this.getCarPassingByTimes();
			this.getCarOutStatisByTimes();
			this.getParkAmountByCondition();
			this.getCarPassingInByMemberTypeAndTime();
			this.getCarPassingOutByMemberTypeAndTime();
		}
	},
	components: {
		"areaDay": () => import("./areaDay"),
		"areaPeriod": () => import("./areaPeriod")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/statistics";
</style>
